import React from "react";

function LightLogo() {
  return (
    <svg
      width="147.2"
      height="32"
      viewBox="0 0 690 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M167.1 45.3501H206.23V52.6601H175.76V71.4201H198.51V78.6401H175.76V97.4801H206.23V104.7H167.1V45.3501Z"
        fill="white"
      />
      <path
        d="M237.14 45.3501H245.46L269.14 104.7H260.32L254.37 89.9301H228.14L222.37 104.7H213.45L237.14 45.3501ZM252 82.7101L241.3 55.0001L230.6 82.7601L252 82.7101Z"
        fill="white"
      />
      <path
        d="M281.07 105.08C278.27 104.643 275.542 103.829 272.96 102.66V94.8501C278.464 97.4124 284.469 98.7173 290.54 98.6701C299.367 98.6701 303.78 95.7001 303.78 89.7601C303.865 88.0019 303.358 86.2661 302.34 84.8301C301.206 83.4523 299.787 82.3367 298.18 81.5601C296.373 80.6267 293.43 79.3401 289.35 77.7001C283.75 75.4401 279.703 72.9501 277.21 70.2301C274.693 67.4286 273.353 63.7643 273.47 60.0001C273.409 57.0285 274.295 54.1145 276 51.6801C277.81 49.1872 280.279 47.2481 283.13 46.0801C286.465 44.6949 290.05 44.014 293.66 44.0801C296.591 44.0548 299.518 44.3127 302.4 44.8501C305.035 45.3643 307.6 46.1901 310.04 47.3101V54.7801C305.353 52.623 300.259 51.4944 295.1 51.4701C291.03 51.4701 287.91 52.2001 285.76 53.6701C284.724 54.3578 283.885 55.3027 283.324 56.4123C282.763 57.5219 282.5 58.7582 282.56 60.0001C282.499 61.6809 282.991 63.3354 283.96 64.7101C285.077 66.138 286.498 67.2994 288.12 68.1101C290.787 69.4763 293.52 70.7078 296.31 71.8001C299.607 73.0825 302.789 74.6449 305.82 76.4701C308.005 77.8041 309.829 79.6551 311.13 81.8601C312.419 84.3027 313.046 87.04 312.95 89.8001C312.95 94.9467 311.11 98.9467 307.43 101.8C303.75 104.653 298.317 106.053 291.13 106C287.756 105.995 284.389 105.687 281.07 105.08Z"
        fill="white"
      />
      <path
        d="M336.64 82.2001L315.07 45.3501H324.33L340.88 74.0001L357.69 45.3801H366.86L345 82.2001V104.7H336.6L336.64 82.2001Z"
        fill="white"
      />
      <path
        d="M382.4 102.15C377.728 99.6906 373.866 95.9332 371.28 91.3301C368.54 86.3563 367.16 80.7476 367.28 75.0701C367.164 69.3803 368.543 63.7597 371.28 58.7701C373.858 54.1482 377.721 50.3726 382.4 47.9001C387.429 45.2857 393.033 43.9724 398.7 44.0801C406.853 44.0801 413.673 46.1468 419.16 50.2801V58.6001C414.24 54.2401 407.673 52.0601 399.46 52.0601C395.232 51.9811 391.049 52.9427 387.28 54.8601C383.802 56.6671 380.925 59.4467 379 62.8601C377.041 66.6299 376.022 70.8176 376.03 75.0662C376.039 79.3148 377.075 83.4983 379.05 87.2601C380.981 90.7005 383.872 93.5044 387.37 95.3301C391.107 97.2475 395.261 98.2095 399.46 98.1301C403.274 98.1866 407.073 97.6294 410.71 96.4801C414.096 95.3262 417.225 93.5225 419.92 91.1701V99.5801C414.833 103.86 407.76 106 398.7 106C393.03 106.099 387.426 104.775 382.4 102.15Z"
        fill="white"
      />
      <path
        d="M429.26 45.3501H453.12C459.46 45.3501 464.327 46.8801 467.72 49.9401C471.113 53.0001 472.813 57.4701 472.82 63.3501C472.939 67.3312 471.709 71.2356 469.33 74.4301C466.858 77.5561 463.382 79.7322 459.49 80.5901L472.56 104.7H463.56L450.91 81.2701H437.91V104.7H429.25L429.26 45.3501ZM451.68 74.0001C455.47 74.0001 458.48 73.1001 460.68 71.3201C462.88 69.5401 464.03 66.8901 464.03 63.3801C464.03 59.5401 462.94 56.7901 460.76 55.1501C458.58 53.5101 455.54 52.6901 451.64 52.6901H437.92V74.0001H451.68Z"
        fill="white"
      />
      <path
        d="M496.5 82.2001L474.94 45.3501H484.19L500.75 74.0001L517.56 45.3801H526.72L504.91 82.2001V104.7H496.5V82.2001Z"
        fill="white"
      />
      <path
        d="M533.94 45.3501H556.78C563.18 45.3501 568.23 46.9501 571.93 50.1501C575.63 53.3501 577.483 57.8934 577.49 63.7801C577.49 69.7801 575.653 74.3201 571.98 77.4001C568.307 80.4801 563.24 82.0134 556.78 82.0001H542.6V104.7H533.94V45.3501ZM555.85 74.8101C559.81 74.8101 562.95 73.9901 565.27 72.3501C567.59 70.7101 568.75 67.8534 568.75 63.7801C568.75 59.7001 567.59 56.8268 565.27 55.1601C562.95 53.4934 559.81 52.6601 555.85 52.6601H542.6V74.8101H555.85Z"
        fill="white"
      />
      <path
        d="M598 52.6601H579.62V45.3501H625.21V52.6601H606.79V104.66H598V52.6601Z"
        fill="white"
      />
      <path
        d="M641.76 102.11C637.075 99.5995 633.193 95.818 630.56 91.2C627.784 86.2844 626.375 80.7146 626.48 75.07C626.375 69.4254 627.784 63.8557 630.56 58.94C633.189 54.2965 637.07 50.4851 641.76 47.94C646.688 45.3715 652.163 44.0303 657.72 44.0303C663.277 44.0303 668.752 45.3715 673.68 47.94C678.373 50.4849 682.257 54.2962 684.89 58.94C687.677 63.8528 689.096 69.4227 689 75.07C689.105 80.7146 687.696 86.2844 684.92 91.2C682.283 95.8184 678.398 99.5997 673.71 102.11C668.772 104.646 663.301 105.969 657.75 105.969C652.199 105.969 646.728 104.646 641.79 102.11H641.76ZM669.14 95.32C672.544 93.4397 675.353 90.6443 677.25 87.25C679.303 83.5241 680.337 79.3231 680.25 75.07C680.33 70.8577 679.31 66.6974 677.29 63C675.438 59.612 672.664 56.8176 669.29 54.94C665.808 53.0189 661.886 52.0367 657.91 52.09C653.902 52.0357 649.945 52.9991 646.41 54.89C642.986 56.7569 640.161 59.5547 638.26 62.96C636.289 66.7103 635.259 70.8834 635.259 75.12C635.259 79.3566 636.289 83.5297 638.26 87.28C640.153 90.6739 642.959 93.4695 646.36 95.35C649.878 97.215 653.799 98.1901 657.78 98.1901C661.761 98.1901 665.682 97.215 669.2 95.35L669.14 95.32Z"
        fill="white"
      />
      <path
        d="M129.4 37.3501V74.5801L97.17 55.9701V55.9601L129.4 37.3501Z"
        fill="#DDCDFA"
      />
      <path
        d="M64.93 111.81V149.03L32.7 130.42V130.41L64.93 111.81Z"
        fill="#EDE6FC"
      />
      <path
        d="M32.7 93.2V130.41H32.68L0.459961 111.81L32.7 93.2Z"
        fill="#A96CF7"
      />
      <path
        d="M32.7 93.1901V93.2001L0.459961 111.81V74.5801L32.7 93.1901Z"
        fill="#AF8AF8"
      />
      <path
        d="M32.7 55.9601V55.9701L0.459961 74.5801V37.3601L32.7 55.9601Z"
        fill="#AF8AF8"
      />
      <path d="M129.4 37.35L97.17 55.96V18.75L129.4 37.35Z" fill="#AF8AF8" />
      <path
        d="M97.1701 55.97V93.18L64.9301 74.58L97.1701 55.97Z"
        fill="#EDE6FC"
      />
      <path d="M129.4 111.81L97.17 130.41V93.2L129.4 111.81Z" fill="#E1D4FB" />
      <path
        d="M97.1701 130.41L64.9301 149.03V111.81L97.1701 130.41Z"
        fill="#EFE7FC"
      />
      <path d="M32.7 18.75V55.96L0.459961 37.36L32.7 18.75Z" fill="#E1D4FB" />
      <path
        d="M97.1701 18.7299L64.9301 37.3499V0.129883L97.1701 18.7299Z"
        fill="#E4D7FB"
      />
      <path d="M64.93 37.36L32.7 55.96V18.74L64.93 37.36Z" fill="#E6DCFC" />
      <path d="M32.7 55.97V93.19L0.459961 74.58L32.7 55.97Z" fill="#A96CF7" />
      <path d="M64.93 111.81L32.7 130.41V93.2L64.93 111.81Z" fill="#DACFF9" />
      <path
        d="M64.93 0.129883V37.3599L32.7 18.7399L64.93 0.129883Z"
        fill="#E1D4FB"
      />
      <path d="M129.4 74.58L97.17 93.18V55.97L129.4 74.58Z" fill="#E1D4FB" />
      <path
        d="M97.1701 93.2V130.41L64.9301 111.81L97.1701 93.2Z"
        fill="#E5DBFB"
      />
      <path
        d="M97.1701 18.73V55.96L64.9301 37.35L97.1701 18.73Z"
        fill="#A96CF7"
      />
    </svg>
  );
}

export default LightLogo;
