import React from "react";
import styled from "styled-components";

type BackgroundProps = {
  children: React.ReactNode;
};
const Purple = styled.div`
  background: #6a36e0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vh 10vw;
`;

function Background({ children }: BackgroundProps) {
  return <Purple>{children}</Purple>;
}

export default Background;
