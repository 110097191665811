import React from "react";
import styled from "styled-components";

type TypoProps = {
  children: React.ReactNode;
  variant: "body" | "header";
  weight?: "bold" | "normal";
};

const Typo = styled.div<TypoProps>`
  font-family: ${({ weight }) =>
    weight === "bold" ? "AxiformaBlack" : "AxiformaRegular"};
  font-size: ${({ variant }) => (variant === "body" ? "16px" : "20px")};
  line-height: ${({ variant }) => (variant === "body" ? "25.34px" : "28.66px")};
  text-align: center;
  color: #ffffff;
`;

function Typography({ children, variant, weight }: TypoProps) {
  return (
    <Typo variant={variant} weight={weight}>
      {children}
    </Typo>
  );
}

export default Typography;
