import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import styled from "styled-components";
import ActivityIndicator from "./ui-components/ActivityIndicator";
import Background from "./ui-components/Background";
import LightLogo from "./ui-components/LightLogo";
import Typo from "./ui-components/Typography";

const LoadingWrapper = styled.div`
  padding: 100px 0px;
`;
const HeadingWrapper = styled.div`
  margin-bottom: 30px;
`;

function App() {
  useEffect(() => {
    const redirectPath = window.location.pathname;

    if (isAndroid || isIOS) {
      if (redirectPath) {
        window.location.replace("com.easycrypto.consumer.custody://holdings");
      } else {
        window.location.replace("com.easycrypto.consumer.custody://login");
      }
    }
    setTimeout(() => {
      window.location.replace(`https://easycrypto.com/nz${redirectPath}`);
    }, 3000);
  }, []);

  return (
    <Background>
      <LightLogo />
      <LoadingWrapper>
        <ActivityIndicator />
      </LoadingWrapper>
      <HeadingWrapper>
        <Typo variant="header" weight="bold">
          Redirecting you.
        </Typo>
      </HeadingWrapper>
      <Typo variant="body">
        Jump back to Easy Crypto if this doesn’t happen automatically.
      </Typo>
    </Background>
  );
}

export default App;
