import React from "react";
import Lottie from "lottie-react";
import styled from "styled-components";

const StyledLottie = styled.div`
  width: 200px;
`;

function ActivityIndicator() {
  return (
    <StyledLottie>
      <Lottie animationData={require("./activity-indicator-light.json")} loop />
    </StyledLottie>
  );
}

export default ActivityIndicator;
