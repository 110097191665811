import { createGlobalStyle } from "styled-components";
import AxiformaRegular from "./Axiforma-Regular.woff";
import AxiformaBlack from "./Axiforma-Black.woff";

export default createGlobalStyle`
    @font-face {
        font-family: 'AxiformaRegular';
        src: url(${AxiformaRegular}) format('woff');
    };
    @font-face {
        font-family: 'AxiformaBlack';
        src: url(${AxiformaBlack}) format('woff');
    };
`;
